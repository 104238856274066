.post-wrap {
    @media only screen and (min-width: 60em) {
        display: flex;
    }
}

.post-body {
    margin-left: 1em;
    margin-right: 1em;
}

.sidebar-wrap {
    width: 20em;
}

.sidebar {
    display: block;
    
    @media only screen and (max-width: 60em) {
        padding-bottom: 3em;
    }
}

.textpost {
    padding-bottom: 5em;    
}

blockquote {
    font-family: var(--title-font);
    font-weight: bold;
    font-style: italic;
    font-size: 2.5rem;
    color: #808080;    
    
    @media only screen and (min-width: 60em) {
        padding-bottom: 3em;        
    }
}
.tag-button {
    display: inline-block;
    background-color: var(--button-inactive-color);
    color: var(--button-text-color);
    border: 1px solid var(--button-inactive-color);
    border-radius: 0.75em;
    padding: 0em 0.3em 0em 0.3em;
    margin-top: 0.2em;
}

.tag-button:hover {
    border-color: var(--button-color);
}

.tag-button.toggled-on {
    background-color: var(--button-color);
    border-color: var(--button-color);
}

.tag-button.toggled-on:hover {
    border-color: var(--button-inactive-color);
}

.post-links li {
    display: none;
}

.post-links li.toggled-on {
    display: block;
}

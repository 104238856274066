.site {
    max-width: 1340px;
    width: 100%;
    background-color: var(--page-background-color);
    margin: 0px auto;
    padding: 0px 5em;

    @media only screen and (max-width: 80em) {
        padding: 0px 3em;
    }

    @media only screen and (max-width: 50em) {
        padding: 0px 2em;
    }
}

.header-main {
    padding: 3em 0px;

    @media only screen and (max-width: 60em) {
        padding-top: 1.5em;
        padding: 2em 0px;
    }
}

.site-branding {
    float: left;
    max-width: 100%;
    margin: 0px;
    padding: 0px;

    @media only screen and (max-width: 60em) {
        float: none;
        text-align: center;
        margin: 0px;        
    }
}

.site-branding a:link, .site-branding a:visited, .site-branding a:hover {
    padding: 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    text-decoration: none;
}

.site-branding .custom-logo {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    margin: 0.5em 1em 0px 0px;
    padding: 0px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;

    @media only screen and (max-width: 60em) {
        margin: 0.5em 0px 0px;
    }
}

.site-content {
    padding: 3em 0px 1em;

    @media only screen and (max-width: 80em) {
        padding: 2em 0px 1em;
    }
}